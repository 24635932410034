<template>
  <div>
    <v-row>
      <v-col
        md="6"
        offset-md="3"
      >
        <v-card>
          <v-card-title>Setup Payments</v-card-title>
          <v-card-text>
            <p class="text-body-1">
              Accepting landing fee payments is a great way to ensure pilots
              have a simple way to pay, without carrying cash.
            </p>
            <p class="text-body-1">
              We've partnered with Stripe, a trusted global payment provider,
              trusted by millions of businesses worldwide, they take the
              friction and pain away from taking payments.
            </p>
            <p class="text-body-1">
              First we need to create a Stripe account, so you can start
              accepting landing fees, click Next to continue.
            </p>

            <p
              v-if="!isStripeOnboardingComplete"
              class="text-body-1 font-weight-bold"
            >
              Looks like you haven't completed setting up your Stripe account,
              try again by clicking Next.
            </p>
          </v-card-text>
          <v-card-actions class="justify-center pb-4">
            <v-btn
              color="primary"
              rounded
              @click="nextStep"
              :loading="isCreating"
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import {supabase} from "@/supabase";

export default {
  created() {
    if (this.hasStripeAccount && this.isStripeOnboardingComplete) {
      this.$router.replace("/admin/airfield/fees-and-charges");
    }
  },

  data() {
    return {
      isCreating: false
    };
  },

  computed: {
    ...mapGetters({
      airfieldId: "auth/airfieldId",
      hasStripeAccount: "auth/airfieldHasStripeAccount",
      isStripeOnboardingComplete: "auth/airfieldStripeOnboardingComplete"
    })
  },

  methods: {
    async nextStep() {
      this.isCreating = true;

      const {data, error} = await supabase.functions.invoke("stripe-createAccount", {
        body: {airfieldId: this.airfieldId}
      });

      if (error) console.error(error);

      if (data?.redirectUrl) {
        window.location.href = data.redirectUrl;
      }

      this.isCreating = false;
    }
  }
};
</script>
