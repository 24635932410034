<template>
  <div>
    <v-row>
      <v-col
        md="6"
        offset-md="3"
      >
        <v-card>
          <v-card-title>Complete Payments Setup</v-card-title>
          <v-card-text>
            <p class="text-body-1">
              Thank you for creating your Stripe account, you can now setup your
              landing fee structure whilst your account is approved to take
              payments.
            </p>
          </v-card-text>
          <v-card-actions class="justify-center pb-4">
            <v-btn
              color="primary"
              rounded
              to="/admin/airfield/fees-and-charges/landing-fees/edit"
            >
              Next
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      hasStripeAccount: "auth/airfieldHasStripeAccount",
      isStripeOnboardingComplete: "auth/airfieldStripeOnboardingComplete"
    })
  }
};
</script>
